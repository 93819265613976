<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="ml-1">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">Home</li>
                <li class="breadcrumb-item active" aria-current="page">
                  {{ $t("distributors.title") }}
                </li>
              </ol>
            </nav>
          </div>
          <div class="row ml-3 mb-3">
            <b-col cols="6">
              <h6 class="ssp-24">{{ $t("distributors.title") }}</h6>
            </b-col>
            <b-col cols="6" class="text-right">
              <button class="btn btn-success btn-add mr-2" @click="onImport">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="20"
                  height="20"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
                {{ $t("global.import") }}
              </button>
              <button
                class="btn btn-success btn-add ml-auto mr-4"
                @click="onAdd"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="20"
                  height="20"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
                {{ $t("distributors.add") }}
              </button>
            </b-col>
          </div>
          <div class="row table-utilities">
            <div class="row ml-2 w-100">
              <div class="col-md-10 search-section">
                <div class="input-group">
                  <div class="input-group-prepend bg-white">
                    <span class="input-group-text" id="basic-addon1">
                      <div class="font-12">
                        <i class="ri-search-line ri-lg ri-sub"></i>
                      </div>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control search-input"
                    :placeholder="$t('distributors.search')"
                    @input="setPage"
                    v-model="filterInput"
                  />
                </div>
              </div>
              <div class="col-md-2 pl-0">
                <button
                  class="btn table-button ssp-14-700 export-button h-100 w-100 d-flex align-items-center"
                  @click="handleExport"
                  :disabled="isLoading"
                >
                  <div class="font-12">
                    <div
                      v-if="isLoading"
                      class="spinner-border spinner-border-sm text-success mr-1"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                    <i v-else class="ri-download-line ri-lg ri-mid mr-1"></i>
                  </div>
                  {{ $t("global.export") }}
                </button>
              </div>
            </div>
            <div class="row w-100 ml-2 mt-2">
              <div class="col-md-2 pl-0">
                <b-button
                  class="btn table-button ssp-14-700 h-100 d-flex align-items-center"
                  id="popover-button-variant"
                  href="#"
                  tabindex="0"
                >
                  <div class="font-12">
                    <i class="ri-filter-2-line ri-lg ri-mid mr-1"></i>
                  </div>
                  {{ $t("global.filter") }}
                </b-button>
              </div>
              <b-popover
                placement="right"
                target="popover-button-variant"
                variant="danger"
                triggers="hover focus"
              >
                <b-tabs content-class="mt-3">
                  <b-tab title="Region">
                    <div class="filter-overflow-srch pr-3">
                      <div
                        class="form-checkbox"
                        v-for="reg in dataRegion"
                        :key="reg.id"
                      >
                        <input
                          class="form-checkbox-item"
                          type="checkbox"
                          name="kabupaten-label"
                          :id="reg.id"
                          :value="reg.id"
                          v-model="filterRegion"
                        />
                        <label
                          class="form-checkbox-label ssp-14-400"
                          :for="reg.id"
                        >
                          {{ reg.region }}
                        </label>
                      </div>
                    </div>

                    <button
                      @click="resetFilter"
                      type="string"
                      class="btn btn-success w-100 mt-2 ssp-14"
                    >
                      {{ $t("filter.reset") }}
                    </button>
                  </b-tab>
                </b-tabs>
              </b-popover>
            </div>
          </div>
          <div class="table-borderless mx-3">
            <b-table
              hover
              :items="dataDistributor"
              :fields="[
                {
                  label: '#',
                  key: 'index'
                },
                {
                  label: $t('distributors.headers[1]'),
                  key: 'name',
                  sortable: true
                },
                {
                  label: $t('distributors.headers[2]'),
                  key: 'actions'
                }
              ]"
              sort-icon-left
              show-empty
              small
              responsive="true"
              class="distributor-table"
            >
              <!-- <template v-slot:head(selected)>
                <input type="checkbox" />
              </template>
              <template v-slot:cell(selected)="id">
                <div @click.stop>
                  <input type="checkbox" />
                </div>
              </template> -->

              <template #cell(index)="data">
                <ul class="list-inline d-inline-block" title="Farmer Name">
                  <li class="list-inline-item">
                    {{ ++data.index + (currPage - 1) * perPage }}
                  </li>
                  <br />
                </ul>
              </template>

              <template #cell(name)="data">
                <ul
                  class="list-inline d-inline-block mw-100"
                  style="width: max-content"
                  title="Farmer Name"
                >
                  <li class="list-inline-item">
                    {{ data.item.name }}
                  </li>
                  <br />
                </ul>
              </template>

              <!-- <template #cell(country)="data">
                <ul
                  class="list-inline d-inline-block mw-100"
                  style="width: max-content"
                  title="Farmer Name"
                >
                  <li class="list-inline-item">
                    {{ data.item.countrys.country }}
                  </li>
                  <br />
                </ul>
              </template> -->

              <template v-slot:cell(actions)="{ item }">
                <ul
                  style="width: max-content"
                  class="list-inline d-inline-block mw-100"
                >
                  <div class="action-wrapper font-12">
                    <i
                      class="ri-eye-fill ri-lg ri-mid action-btn"
                      @click="onDetail(item)"
                    ></i>
                    <i
                      class="ri-pencil-fill ri-lg ri-mid action-btn"
                      @click="onEdit(item)"
                    ></i>
                    <i
                      class="ri-delete-back-2-fill ri-lg ri-mid action-btn"
                      @click="onDelete(item.id)"
                    ></i>
                  </div>
                </ul>
              </template>
            </b-table>

            <div class="row align-items-center table-footer">
              <div class="col-sm-6 ssp-14-700">
                <!-- 1 - 6 of {{ this.totalData }} -->
                {{ $t("global.total_data") }} {{ this.totalData }}
              </div>
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-7 align-self-center page-text">
                    {{ $t("global.page_on") }}
                  </div>
                  <div class="col float-right">
                    <div class="row">
                      <select
                        class="form-control pagination-pg"
                        v-model="currPage"
                      >
                        <option
                          v-for="index in totalPages"
                          :key="index.id"
                          :value="index"
                        >
                          {{ index++ }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col page-arrow ssp-14-700 ml-2">
                    <div class="row float-right">
                      <div
                        @click="redcPage"
                        class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                      >
                        <div class="font-12" :class="{ orange: currPage > 1 }">
                          <i class="ri-arrow-left-line ri-lg ri-sub"></i>
                        </div>
                      </div>
                      <div
                        @click="addPage"
                        class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                      >
                        <div
                          class="font-12"
                          :class="{ orange: currPage < totalPages }"
                        >
                          <i class="ri-arrow-right-line ri-lg ri-sub"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
    <b-modal id="modal-distributor" centered hide-footer>
      <template #modal-header="{}">
        <!-- Emulate built in modal header close button action -->
        <h5 class="ssp-24 text-black">{{ modalTitle }}</h5>
      </template>

      <template #default="{ hide }">
        <!-- <p class="">Modal Body with button</p> -->
        <form @submit="onSubmit">
          <div class="form-group">
            <label for="nama-distributor" class="ssp-16 text-black">
              {{ $t("distributors.label.name") }}
              <span v-if="!isDetail" class="text-red">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              :class="{ 'invalid-form': $v.formTambah.name.$error }"
              id="nama-distributor"
              v-model="$v.formTambah.name.$model"
              :placeholder="
                !isDetail ? $t('distributors.placeholder.name') : ''
              "
              :disabled="isDetail"
            />
          </div>

          <div class="form-group">
            <label for="nama-pemilik" class="ssp-16 text-black">
              {{ $t("distributors.label.owner") }}
              <span v-if="!isDetail" class="text-red">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              id="nama-pemilik"
              v-model="$v.formTambah.owner.$model"
              :class="{ 'invalid-form': $v.formTambah.owner.$error }"
              :placeholder="
                !isDetail ? $t('distributors.placeholder.owner') : ''
              "
              :disabled="isDetail"
            />
          </div>
          <div class="form-group">
            <label for="email-distributor" class="ssp-16 text-black">
              {{ $t("distributors.label.email") }}
            </label>
            <input
              type="email"
              class="form-control"
              id="email-distributor"
              v-model="$v.formTambah.email.$model"
              :placeholder="
                !isDetail ? $t('distributors.placeholder.email') : ''
              "
              :disabled="isDetail"
            />
          </div>

          <div class="form-group">
            <label class="typo__label ssp-16 text-black">
              {{ $t("distributors.label.country") }}
              <span v-if="!isDetail" class="text-red">*</span>
            </label>
            <multiselect
              :placeholder="$t('distributors.placeholder.country')"
              v-model="formTambah.country_id"
              :options="dataCountry"
              label="country"
              :allow-empty="false"
              :disabled="isDetail"
              id="dist-country"
              :class="{
                'invalid-form rounded-input': $v.formTambah.country_id.$error
              }"
            />
          </div>

          <div class="form-group">
            <label class="ssp-16 text-black">
              {{ $t("distributors.label.region") }}
              <span v-if="!isDetail" class="text-red">*</span>
            </label>
            <multiselect
              :placeholder="$t('distributors.placeholder.region')"
              v-model="formTambah.region_id"
              :options="dataRegion"
              label="region"
              :allow-empty="false"
              :disabled="isDetail"
              id="dist-region"
              :class="{
                'invalid-form rounded-input': $v.formTambah.region_id.$error
              }"
            ></multiselect>
          </div>

          <div class="form-group">
            <label class="ssp-16 text-black">
              {{ $t("distributors.label.province") }}
              <span v-if="!isDetail" class="text-red">*</span>
            </label>
            <multiselect
              :placeholder="$t('distributors.placeholder.province')"
              v-model="formTambah.province_id"
              :options="dataProvinsi"
              label="province"
              :disabled="isDetail"
              :allow-empty="false"
              id="dist-prov"
              @search-change="searchProv"
              :class="{
                'invalid-form rounded-input': $v.formTambah.province_id.$error
              }"
            ></multiselect>
          </div>

          <div class="form-group">
            <label class="ssp-16 text-black">
              {{ $t("distributors.label.district") }}
              <span v-if="!isDetail" class="text-red">*</span>
            </label>
            <multiselect
              :placeholder="$t('distributors.placeholder.district')"
              v-model="formTambah.kabupaten_id"
              :options="dataKabupaten"
              label="kabupaten"
              :disabled="isDetail"
              :allow-empty="false"
              id="dist-kabs"
              @search-change="searchKab"
              :class="{
                'invalid-form rounded-input': $v.formTambah.kabupaten_id.$error
              }"
            ></multiselect>
          </div>

          <div class="form-group">
            <label class="ssp-16 text-black">
              {{ $t("distributors.label.address") }}
            </label>
            <textarea
              name="alamat-distributor"
              class="form-control"
              id="alamat-distributor"
              cols="30"
              rows="4"
              :disabled="isDetail"
              v-model="$v.formTambah.alamat.$model"
              :placeholder="
                !isDetail ? $t('distributors.placeholder.address') : ''
              "
            />
          </div>

          <div class="form-group">
            <label class="ssp-16 text-black">
              {{ $t("distributors.label.mobile") }}
            </label>
            <input
              v-if="!isDetail"
              type="text"
              class="form-control"
              id="telp-distributor"
              v-model="$v.formTambah.mobile.$model"
              :placeholder="
                !isDetail ? $t('distributors.placeholder.mobile') : ''
              "
            />
            <input
              v-else
              type="text"
              class="form-control"
              v-model="$v.formTambah.mobile.$model"
              disabled
            />
          </div>

          <div class="form-group">
            <label class="ssp-16 text-black">
              {{ $t("distributors.label.phone") }}
            </label>
            <input
              v-if="!isDetail"
              type="text"
              class="form-control"
              id="telp-distributor"
              v-model="$v.formTambah.telephone.$model"
              :placeholder="$t('distributors.placeholder.phone')"
            />
            <input
              v-else
              type="text"
              class="form-control"
              v-model="$v.formTambah.telephone.$model"
              disabled
            />
          </div>
          <button
            v-if="!isDetail"
            type="submit"
            class="btn btn-primary ssp-14 w-100 my-3 py-2"
          >
            {{ $t("global.save") }}
          </button>
        </form>
      </template>
    </b-modal>
    <b-modal id="modal-import-distributor" hide-footer>
      <template #modal-header="{ close }">
        <h5 class="ssp-24 text-black">{{ $t("global.import") }}</h5>
        <div class="pointer font-14" @click="close()">
          <i class="ri-close-circle-line ri-xl ri-mid"></i>
        </div>
      </template>

      <template #default="{ hide }">
        <form @submit="handleImport">
          <div class="form-group">
            <label class="ssp-16 text-black">
              {{ $t("global.excel_label") }}
              <span class="text-red"> *</span>
            </label>
            <b-form-file
              v-model="dataImport"
              :placeholder="$t('global.excel_pch')"
              drop-placeholder="Drop file here..."
              accept=".csv, .xls, .xlsx"
            >
            </b-form-file>
          </div>
          <div class="form-group mb-0">
            <div class="mb-1">
              {{ $t("distributors.rule.index") }}
              <ul class="no-bullets">
                <li v-for="rule in $t('distributors.rule.rule')" :key="rule.id">
                  {{ `- ${rule}` }}
                </li>
              </ul>
            </div>
            <a
              :href="
                $window.location.origin + '/files/distributor-template.xlsx'
              "
            >
              distributor-template.xlsx
            </a>
          </div>
          <button
            type="submit"
            class="btn btn-primary ssp-14 w-100 my-3 py-2"
            :disabled="importLoading"
          >
            <b-spinner
              small
              label="Small Spinner"
              class="mr-1"
              v-if="importLoading"
            ></b-spinner>
            {{ $t("global.submit_btn") }}
          </button>
        </form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { Card } from "@/components/index";

import Multiselect from "vue-multiselect";
import "../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";
import { required } from "vuelidate/lib/validators";
import {
  Q_LIST_COUNTRY,
  Q_LIST_REGION,
  Q_LIST_PROVINCE,
  Q_LIST_KABUPATEN,
  // Q_LIST_KECAMATAN,
  // Q_LIST_KELURAHAN,
  // M_ADD_REGION,
  M_ADD_DISTRIBUTOR,
  EDIT_DISTRIBUTOR,
  M_DEL_DISTRIBUTOR,
  Q_LIST_DISTRIBUTOR
} from "@/graphql/super_admin";
import Axios from "axios";
import jwtService from "@/core/services/jwt.service";

export default {
  components: {
    Card,
    multiselect: Multiselect
  },
  data() {
    return {
      isLoading: false,
      fieldsDistributor: [
        {
          label: "#",
          key: "index"
        },
        {
          label: this.$t("distributors.headers[1]"),
          key: "name",
          sortable: true
        },
        {
          label: this.$t("distributors.headers[2]"),
          key: "actions"
        }
      ],

      filters: {
        region_id: "",
        province_id: "",
        kabupaten_id: ""
      },

      filters1: {
        region_id: [],
        province_id: [],
        kabupaten_id: []
      },
      filterRegion: [],

      dataDistributor: [],
      dataCountry: [],
      dataRegion: [],
      dataProvinsi: [],
      dataKabupaten: [],
      dataKecamatan: [],
      dataKelurahan: [],

      importLoading: false,
      totalPages: null,
      currPage: 1,
      perPage: 10,
      totalData: null,
      filterInput: "",

      modalTitle: "",
      isAdd: false,
      isEdit: false,
      isDetail: false,
      dataImport: null,

      formTambah: {
        name: null,
        owner: null,
        email: null,
        country_id: null,
        region_id: null,
        province_id: null,
        kabupaten_id: null,
        kecamatan_id: null,
        kelurahan_id: null,
        kodepos: null,
        alamat: null,
        jalan: null,
        longitude: null,
        latitude: null,
        mobile: null,
        telephone: null
      }
    };
  },
  validations: {
    formTambah: {
      name: { required },
      owner: { required },
      email: {},
      country_id: { required },
      region_id: { required },
      province_id: { required },
      kabupaten_id: { required },
      kecamatan_id: {},
      kelurahan_id: {},
      kodepos: {},
      alamat: {},
      jalan: {},
      longitude: {},
      latitude: {},
      mobile: {},
      telephone: {}
    }
  },
  apollo: {
    listDistributor: {
      query: () => Q_LIST_DISTRIBUTOR,
      variables() {
        return {
          page: this.currPage,
          limit: this.perPage,
          keyword: this.filterInput,
          region_id: this.filterRegion,
          province_id: [],
          kabupaten_id: []
        };
      },
      debounce: 500,
      result({ data }) {
        this.dataDistributor = data.listDistributor.distributors;
        this.totalPages = data.listDistributor.totalPage;
        this.totalData = data.listDistributor.total;
      },
      error(err) {
        this.catchError(err);
      }
    },
    listCountry: {
      query: Q_LIST_COUNTRY,
      result({ data }) {
        this.dataCountry = data.listCountry.countrys;
      }
    },
    listRegion: {
      query: Q_LIST_REGION,
      variables() {
        return {
          page: 1,
          limit: 25,
          keyword: ""
        };
      },
      result({ data }) {
        this.dataRegion = data.listRegion.regions;
      }
    },
    listProvince: {
      query: Q_LIST_PROVINCE,
      variables() {
        return {
          keyword: this.filters.province_id,
          region_id: this.isAdd
            ? this.formTambah.region_id
              ? this.formTambah.region_id.id
              : ""
            : this.filters1.region_id[0]
            ? this.filters1.region_id[0].id
            : ""
        };
      },
      result({ data }) {
        this.dataProvinsi = data.listProvince.provinces;
      }
    },
    listKabupaten: {
      query: Q_LIST_KABUPATEN,
      variables() {
        return {
          keyword: this.filters.kabupaten_id,
          province_id: this.isAdd
            ? this.formTambah.province_id
              ? this.formTambah.province_id.id
              : ""
            : this.filters1.province_id[0]
            ? this.filters1.province_id[0].id
            : ""
        };
      },
      result({ data }) {
        this.dataKabupaten = data.listKabupaten.kabupatens;
      }
    }
  },
  methods: {
    searchProv(query) {
      this.filters.province_id = query;
    },
    searchKab(query) {
      this.filters.kabupaten_id = query;
    },
    searchKec(query) {
      this.filters.kecamatan_id = query;
    },
    searchKel(query) {
      this.filters.kelurahan_id = query;
    },
    resetFilter() {
      this.filterRegion = [];
    },
    onAdd() {
      this.isAdd = true;
      this.modalTitle = this.$t("distributors.add");
      this.isDetail = false;
      this.isEdit = false;
      this.resetForm();
      this.$bvModal.show("modal-distributor");
    },
    closeModal() {
      this.isAdd = false;
      this.$bvModal.hide("modal-distributor");
    },
    resetForm() {
      this.formTambah = {
        name: null,
        owner: null,
        email: null,
        country_id: null,
        region_id: null,
        province_id: null,
        kabupaten_id: null,
        kecamatan_id: null,
        kelurahan_id: null,
        kodepos: null,
        alamat: null,
        jalan: null,
        longitude: null,
        latitude: null,
        mobile: null,
        telephone: null
      };
      this.$v.$reset();
    },
    setPage() {
      this.currPage = 1;
    },
    addPage() {
      if (this.currPage < this.totalPages) {
        this.currPage++;
      }
    },
    redcPage() {
      if (this.currPage > 1) {
        this.currPage--;
      }
    },
    onDetail(data) {
      this.modalTitle = this.$t("distributors.detail");
      this.isEdit = false;
      this.isDetail = true;
      this.resetForm();
      this.formTambah = data;
      if (data.countrys) this.formTambah.country_id = data.countrys;
      if (data.regions) this.formTambah.region_id = data.regions;
      if (data.provinces) this.formTambah.province_id = data.provinces;
      if (data.kabupatens) this.formTambah.kabupaten_id = data.kabupatens;
      if (data.kecamatans) this.formTambah.kecamatan_id = data.kecamatans;
      if (data.kelurahans) this.formTambah.kelurahan_id = data.kelurahans;
      this.formTambah.kodepos = data.zip;

      this.$bvModal.show("modal-distributor");
    },
    onEdit(data) {
      this.modalTitle = this.$t("distributors.edit");
      this.isEdit = true;
      this.isDetail = false;
      this.resetForm();
      this.formTambah = data;
      if (data.countrys) this.formTambah.country_id = data.countrys;
      if (data.regions) this.formTambah.region_id = data.regions;
      if (data.provinces) this.formTambah.province_id = data.provinces;
      if (data.kabupatens) this.formTambah.kabupaten_id = data.kabupatens;
      if (data.kecamatans) this.formTambah.kecamatan_id = data.kecamatans;
      if (data.kelurahans) this.formTambah.kelurahan_id = data.kelurahans;
      this.formTambah.kodepos = data.zip;

      this.$bvModal.show("modal-distributor");
    },
    async onSubmit(ev) {
      ev.preventDefault();
      this.$v.$touch();
      if (!this.$v.$error) {
        if (this.isEdit) {
          return new Promise((resolve, reject) => {
            const addDistributor = this.$apollo.mutate({
              mutation: EDIT_DISTRIBUTOR,
              variables: {
                id: this.formTambah.id,
                name: this.formTambah.name,
                owner: this.formTambah.owner,
                email: this.formTambah.email,
                country_id: this.formTambah.country_id
                  ? parseInt(this.formTambah.country_id.id)
                  : "",
                region_id: this.formTambah.region_id
                  ? parseInt(this.formTambah.region_id.id)
                  : "",
                province_id: this.formTambah.province_id
                  ? this.formTambah.province_id.id
                  : "",
                kabupaten_id: this.formTambah.kabupaten_id
                  ? this.formTambah.kabupaten_id.id
                  : "",
                // kecamatan_id: this.formTambah.kecamatan_id
                //   ? this.formTambah.kecamatan_id.id
                //   : "",
                // kelurahan_id: this.formTambah.kelurahan_id
                //   ? this.formTambah.kelurahan_id.id
                //   : "",
                // kodepos: parseInt(this.formTambah.kodepos),
                alamat: this.formTambah.alamat,
                // jalan: this.formTambah.jalan,
                // longitude: this.formTambah.longitude,
                // latitude: this.formTambah.latitude,
                mobile: this.formTambah.mobile,
                telephone: this.formTambah.telephone
              }
            });
            if (resolve) {
              resolve(addDistributor);
            } else {
              reject(addDistributor);
            }
          })
            .then(response => {
              if (response) {
                this.toastAlert(this.$t("alert.edit"));
                this.closeModal();
                this.$apollo.queries.listDistributor.refetch();
              }
            })
            .catch(err => {
              this.$swal.fire({
                icon: "error",
                title: "Error!",
                text: err.message,
                width: 400,
                confirmButtonText: "OK"
              });
            });
        } else {
          return new Promise((resolve, reject) => {
            const addDistributor = this.$apollo.mutate({
              mutation: M_ADD_DISTRIBUTOR,
              variables: {
                name: this.formTambah.name,
                owner: this.formTambah.owner,
                email: this.formTambah.email,
                country_id: this.formTambah.country_id
                  ? parseInt(this.formTambah.country_id.id)
                  : "",
                region_id: this.formTambah.region_id
                  ? parseInt(this.formTambah.region_id.id)
                  : "",
                province_id: this.formTambah.province_id
                  ? this.formTambah.province_id.id
                  : "",
                kabupaten_id: this.formTambah.kabupaten_id
                  ? this.formTambah.kabupaten_id.id
                  : "",
                // kecamatan_id: this.formTambah.kecamatan_id
                //   ? this.formTambah.kecamatan_id.id
                //   : "",
                // kelurahan_id: this.formTambah.kelurahan_id
                //   ? this.formTambah.kelurahan_id.id
                //   : "",
                // kodepos: parseInt(this.formTambah.kodepos),
                alamat: this.formTambah.alamat,
                // jalan: this.formTambah.jalan,
                // longitude: this.formTambah.longitude,
                // latitude: this.formTambah.latitude,
                mobile: this.formTambah.mobile,
                telephone: this.formTambah.telephone
              }
            });
            if (resolve) {
              resolve(addDistributor);
            } else {
              reject(addDistributor);
            }
          })
            .then(response => {
              if (response) {
                this.toastAlert(this.$t("alert.save"));
                this.closeModal();
                this.$apollo.queries.listDistributor.refetch();
              }
            })
            .catch(err => {
              this.errorAlert(err);
            });
        }
      }
    },
    onDelete(id) {
      this.deleteConfirm().then(async result => {
        if (result.isConfirmed) {
          return new Promise((resolve, reject) => {
            const delR = this.$apollo.mutate({
              mutation: M_DEL_DISTRIBUTOR,
              variables: {
                id: id
              }
            });
            if (resolve) {
              resolve(delR);
            } else {
              reject(delR);
            }
          })
            .then(response => {
              if (response) {
                this.toastAlert(this.$t("alert.delete"));
                this.$apollo.queries.listDistributor.refetch();
              }
            })
            .catch(err => {
              this.errorAlert(err);
            });
        }
      });
    },
    onImport() {
      this.$bvModal.show("modal-import-distributor");
    },
    handleImport(e) {
      e.preventDefault();
      this.importLoading = true;
      let formData = new FormData();
      formData.append("file", this.dataImport);
      Axios.post(
        `${process.env.VUE_APP_URL_GRAPHQL}/distributor/import`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + jwtService.getToken(),
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          }
        }
      )
        .then(response => {
          if (response) {
            this.importLoading = false;
            this.$bvModal.hide("modal-import-distributor");
            this.dataImport = null;
            this.$apollo.queries.listDistributor.refetch();
            this.toastAlert(this.$t("alert.import"));
          }
        })
        .catch(err => {
          this.importLoading = false;
          this.$swal.fire({
            icon: "error",
            title: "Error!",
            text: err.message,
            width: 400,
            confirmButtonText: "OK"
          });
        });
    },
    // async     async handleExport() {
    // this.isLoading = true;
    //   try {
    //     const result = await this.$apollo.query({
    //       query: Q_LIST_DISTRIBUTOR,
    //       variables: {
    //         page: 1,
    //         limit: 99999999,
    //         keyword: this.filterInput
    //       }
    //     });
    //     const list = result.data.listDistributor.distributors;
    //     import("@/plugins/export-excel").then(excel => {
    //       const tHeader = [
    //         "Name",
    //         "Owner",
    //         "Email",
    //         "Kode Pos",
    //         "Alamat",
    //         "Jalan",
    //         "Lat",
    //         "Lng",
    //         "Mobile",
    //         "Telp",
    //         "Country",
    //         "Region",
    //         "Province",
    //         "Kabupaten"
    //         // "Kecamatan",
    //         // "Kelurahan"
    //       ];
    //       const filterVal = [
    //         "name",
    //         "owner",
    //         "email",
    //         "zip",
    //         "alamat",
    //         "jalan",
    //         "latitude",
    //         "longitude",
    //         "mobile",
    //         "telephone",
    //         "countrys",
    //         "regions",
    //         "provinces",
    //         "kabupatens"
    //         // "kecamatans",
    //         // "kelurahans"
    //       ];
    //       setTimeout(() => {
    //         list.map(a => {
    //           if (a.countrys) {
    //             a.countrys = a.countrys.country;
    //           }
    //           if (a.regions) {
    //             a.regions = a.regions.region;
    //           }
    //           if (a.provinces) {
    //             a.provinces = a.provinces.province;
    //           }
    //           if (a.kabupatens) {
    //             a.kabupatens = a.kabupatens.kabupaten;
    //           }
    //           // if (a.kecamatans) {
    //           //   a.kecamatans = a.kecamatans.kecamatan;
    //           // }
    //           // if (a.kelurahans) {
    //           //   a.kelurahans = a.kelurahans.kelurahan;
    //           // }
    //           return a;
    //         });
    //         const data = this.formatJson(filterVal, list);
    //         excel.export_json_to_excel({
    //           header: tHeader,
    //           data,
    //           filename: this.$route.name,
    //           autoWidth: true,
    //           bookType: "xlsx"
    //         });
    //       }, 1500);
    //       this.limit = 10;
    //     });
    //   } catch (e) {
    //     this.errorAlert(e);
    //   }
    // }
    async handleExport() {
      this.isLoading = true;
      await Axios.get(process.env.VUE_APP_URL_GRAPHQL + "/distributor/excel", {
        responseType: "arraybuffer",
        headers: {
          Authorization: "Bearer " + jwtService.getToken(),
          "Content-Type": "application/json"
        }
      })
        .then(response => {
          const type = response.headers["content-type"];
          const blob = new Blob([response.data], {
            type: type,
            encoding: "UTF-8"
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `distributor-${this.dateFr(
            new Date(),
            "DDMMYYYYHHMMss"
          )}.xlsx`;
          link.click();
        })
        .catch(e => {
          alert(e);
        });
      this.isLoading = false;
    }
  },
  mounted() {}
};
</script>

<style>
.distributor-table thead {
  background-color: #fafafa;
  border-radius: 50px;
}

.distributor-table tbody tr td {
  /* background-color: red; */
  padding-top: 0.8rem;
  padding-bottom: 0.9rem;
  /* vertical-align: middle; */
}

.distributor-table tbody tr td ul {
  margin-bottom: 0;
}

ul.no-bullets {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}
</style>
